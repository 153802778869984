body.dark-theme{

    color: #D5D8DF !important;
    background-color: #181c34 !important;

    .border-0, .settings-sidebar input, .form-control.border-0, .bootstrap-select > .dropdown-toggle {
        border: 0px !important;
    }

    .bg-dark, .qs-controls, .dropdown-item:hover, .dropdown-item:focus, .task_view .task_view_more:hover, .task_view .taskView:hover, .project-menu.in, .bg-amt-grey, .e-d-tasks-projects-events .bg-grey, .cal-info .bg-light-grey, .inv-num-date .bg-light-grey, .inv-detail .bg-light-grey, .message_wrapper .msg-content-left .tablinks.active, .message_wrapper .msg-content-left .card:hover, .project-right .p-activity-heading span, .settings-sidebar li.active, .board-panel, .w-task-board-panel .minimized, .task-search .input-group-text, .ticket-msg .card-horizontal.bg-white-shade, .bg-white-shade.ticket-contact-owner, .dark_place .input-group-text, .login_section, .daterangepicker .ranges li:hover, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date,  .table .thead-light th, .badge-light, .datepicker .datepicker-switch:hover, .datepicker .next:hover, .datepicker .prev:hover, .datepicker tfoot tr th:hover, .datepicker table tr td.day:hover, .datepicker table tr td.focused, .tagify__tag>div, .tagify__tag__removeBtn, .fc-button.fc-button-primary.fc-button-active, .fc-day-today, .in-range.available, .daterangepicker td.available:hover, .daterangepicker th.available:hover, .dropify-wrapper .dropify-preview, .dropify-wrapper, .bg-white-shade, .input-group-text, .progress, .swal2-popup, .main-sidebar  {
        background-color: #29304C !important;
    }
    .bg-white, .card, .form-control, .qs-datepicker-container, .qs-overlay, .unpaid, .custom-file-label, .p-client-msg .btn-secondary, .w-task-board-panel, .b-p-header .b-p-badge, .content-wrapper, .admin-dashboard, .page-title, .main-container, .settings-box, .input-group .bootstrap-select.form-control .dropdown-toggle, .bootstrap-select > .dropdown-toggle, .bootstrap-timepicker-widget table td a:hover, .btn-cancel, .select-picker.role-permission-select, .fc .fc-button-primary, .daterangepicker td.off, .dropzone, textarea,iframe .form-body, .tabs .-secondary, .set-btns, .list-group-item, .bg-light, .bg-grey, .dropzone .dz-preview.dz-image-preview {
        background-color: #181C34 !important;
    }
    .text-dark, .text-dark-grey, .text-darkest-grey,  .table tr td, .sidebar-brand-box .sidebar-brand-dropdown .profile-box a, .qs-datepicker-container, .qs-day, .table tr td a, .tabs .nav .nav-item, .login_box h3, .form-control, .forgot_pswd a, .login_box .form-group label, .page-item.disabled .page-link, .pagination li a, .dropdown-item, a:hover, .filter-option-inner-inner, .badge-light, .bootstrap-timepicker-widget table td a, .ql-snow .ql-picker, .ql-snow .ql-stroke, .form_custom_label, .dropdown-menu.datepicker-dropdown, .select-picker.role-permission-select, .tagify__tag>div>*, .tagify__tag__removeBtn, .dropdown-menu.dashboard-settings, .daterangepicker td.in-range, .text-muted, .input-group-text, .bootstrap-select .dropdown-toggle::after, .btn-primary:hover .text-white, .fc .fc-col-header-cell-cushion, .swal2-title, .swal2-content, .login_box a, .bg-grey, .bg-grey span {
        color: #D5D8DF !important;
    }
    .chart-container .axis, .chart-container .chart-label, .data-point-value{
        fill: #D5D8DF !important;
    }
    .ql-snow .ql-stroke{
        stroke: #D5D8DF;
    }
    .filter-box, .table tr td, .login_header{
        box-shadow: 0 1px 0 0 #4B4E69;
    }
    .border-right-grey, .task_view .taskView, .border-right{
        border-right: 1px solid #4B4E69 !important;
    }
    .border-left-grey, .more-filters, .daterangepicker.show-ranges.ltr .drp-calendar.left, .border-left, .c-inv-btns .dropdown-toggle span{
        border-left: 1px solid #4B4E69 !important;
    }
    .border-bottom-grey, .main-header, .modal-header, .modal-body, .message_wrapper .msg-header, .border-bottom, .nav-tabs, .tabs.--show-secondary .-secondary li{
        border-bottom: 1px solid #4B4E69 !important;
    }
    .border-top-grey, .modal-footer, .more-filters .more-filter-tab.in .clear-all, .daterangepicker .drp-buttons, .border-top {
        border-top: 1px solid #4B4E69 !important;
    }
    .b-shadow-4, .qs-datepicker-container, .form-control, .w-tables, .c-inv-desc table tr td, .border, .table-bordered, .c-inv-total .c-inv-total-right td{
        box-shadow: none;
        border: 1px solid #4B4E69 !important;
    }
    .sidebar-brand-box .sidebar-brand-dropdown, .dropdown-menu, .add-client, .modal-content,input, tbody, .message_wrapper .msg-content-right .mbl-sender-name, .more-filters .more-filter-tab, .daterangepicker{
        background-color: #181C34;
        border: 1px solid #4B4E69 !important;
    }

    .modal.show {
        background-color: rgb(0 0 0 / 47%);
    }
    
    .menu-collapse:before{
        background: none;
    }

    .recent-activity .res-activity-list:after {
        border: 1px solid #4B4E69 !important;
    }

    .select-box .date-picker-field, .select-box .date-picker-field1, .msg-content-left input{
        border: none !important;
        color: #D5D8DF !important;
    }
    .close{
        color: #fff;
    }
    .table thead th{
        box-shadow: none;
        border-bottom: 1px solid #4B4E69 !important;
        color: #D5D8DF !important;
    }
    .c-inv-disc td, .inv-num-date tr td, .inv-detail tr td, .message_wrapper .msg-content-left, .message_wrapper, .message_wrapper .msg-content-left .card, .message_wrapper .input-group, .settings-sidebar, .border-grey, .ticket-wrapper, .task-detail-panel, ul.thumbnails.image_picker_selector li .thumbnail, .task_view, .fc-theme-standard .fc-scrollgrid, .fc-theme-standard td, .fc-theme-standard th, .fc .fc-button-primary, .dropify-wrapper, .input-group-text, .fc-theme-standard .fc-list, .tagify, .list-group-item  {
        border: 1px solid #4B4E69 !important;
    }
    .dash-border-top {
        border-top: 1px dashed #4B4E69 !important;
    }
    .daterangepicker .ranges li.active, .page-link:hover, .permisison-table .bg-light, .w-task-board-box:after{
        background-color: #4B4E69 !important;
    }
    .ql-toolbar.ql-snow, .ql-container.ql-snow, .daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span{
        border-color: #4B4E69;
    }
    .daterangepicker .calendar-table{
        border: 1px solid #181c34;
        background-color: #181c34;
    }
    .page-item.disabled .page-link, .pagination li a{
        background-color: #29304c;
        border-color: #29304c;
    }
    .filter-box .select-box .bootstrap-select .dropdown-toggle, .filter-box .select-box .bootstrap-select .btn-light:not(:disabled):not(.disabled):active, .filter-box .select-box .bootstrap-select .btn-light:not(:disabled):not(.disabled).active, .btn:hover, .message_wrapper .msg-content-right .chat-box .card:hover p{
        color: #9c9fa6 !important;
    }
    .pagination .page-item.active .page-link, .page-item.active .page-link, .dropdown-item.active, .dropdown-item:active:hover, .btn-primary{
        background-color: #D5D8DF !important;
        border-color: #D5D8DF;
    }
    .page-item.active .page-link, .dropdown-item.active, .dropdown-item.active:hover, .text-white{
        color: #181c34 !important;
    }
    .daterangepicker td.off.end-date, .daterangepicker td.active.end-date{
        background-color: #616e80 !important;
    }
    .bootstrap-timepicker-widget.dropdown-menu:after{
        border-bottom: 6px solid #4b4e69;
    }
    .tagify__tag>div::before{
        box-shadow: 0 0 0 var(--tag-inset-shadow-size,1.1em) #29304c inset;
    }

    ::placeholder, .tagify__input:empty::before{
        color: #a7aaaf !important;

        :focus{
            &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            opacity: 0.5;
            transition: all 0.3s ease
            }
            &::-moz-placeholder { /* Firefox 19+ */
            opacity: 0.5;
            transition: all 0.3s ease
            }
            &:-ms-input-placeholder { /* IE 10+ */
            opacity: 0.5;
            transition: all 0.3s ease
            }
            &:-moz-placeholder { /* Firefox 18- */
            opacity: 0.5;
            transition: all 0.3s ease
            }
        }
    }
    .btn-active, .btn-active.btn-secondary{
        background-color: #616e80 !important;
        border: 1px solid #616e80 !important;
    }
    .btn-primary {
        background-color: #ffffff !important;
        color: #181c34 !important;
        border: 1px solid #ffffff !important;

        &:hover{
            background-color: #000000 !important;
            border: 1px solid #000000 !important;
            color: #fff !important;
        }
    }
    .btn-secondary {
        border: solid 1px #616e80;
        background-color: #29304c !important;
        color: #bec1ca !important;

        &:hover{
            background-color: #000000 !important;
            border: 1px solid #000000 !important;
        }
    }
    .daterangepicker:before{
        border-bottom: 7px solid #4b4e69;
    }
    .daterangepicker:after{
        border-bottom: 6px solid #4b4e69;
    }
    .alert-secondary {
        color: #e2e3e8;
        background-color: #29304c;
        border-color: #4b4e69;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px #29304c inset !important;
        box-shadow: 0 0 0 30px #29304c inset !important;
    }
    input:-webkit-autofill{
        -webkit-text-fill-color: white !important;
    }
    .msg-content-left .py-1, .settings-sidebar .py-1{
        padding: 0px !important;
    }
    .msg-content-left .py-1{
        flex-wrap: nowrap;
    }

    .fc-theme-standard .fc-listWeek-view table td {
        border: none !important;
    }

    tr.alert-primary {
        background-color: #212851;
        border-color: #212851;
    }

    .alert .text-dark-grey {
        color: #616e80 !important;
    }
    
    .dropdown-item.active .text-muted {
        color: #6c757d !important;
    }
    .dropdown-item.active .text-darkest-grey {
        color: $darkest_grey !important;
    }

    .sidebar-menu::-webkit-scrollbar {
        width: 10px;
        background:#262f3a ;
    }
    .sidebar-menu::-webkit-scrollbar-thumb {
        background-color:#585858 ;
        border: 3px solid #262f3a ;
    }
      
}
body.dark-theme::-webkit-scrollbar,
body.dark-theme .t-d-inner-panel::-webkit-scrollbar,
body.dark-theme .show::-webkit-scrollbar,
body.dark-theme .b-p-body::-webkit-scrollbar,
body.dark-theme .w-task-board-panel::-webkit-scrollbar,
body.dark-theme .settings-menu::-webkit-scrollbar,
body.dark-theme .card-body::-webkit-scrollbar {
    width: 10px;
    background: #262f3a;
}
body.dark-theme::-webkit-scrollbar-thumb,
body.dark-theme .t-d-inner-panel::-webkit-scrollbar-thumb,
body.dark-theme .show::-webkit-scrollbar-thumb,
body.dark-theme .b-p-body::-webkit-scrollbar-thumb,
body.dark-theme .w-task-board-panel::-webkit-scrollbar-thumb,
body.dark-theme .settings-menu::-webkit-scrollbar-thumb,
body.dark-theme .card-body::-webkit-scrollbar-thumb {
    background-color: #585858;
    border: 3px solid #262f3a;
}

